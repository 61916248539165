/* eslint-disable import/no-anonymous-default-export */
import {
    GET_ALL_HUDAWI_ANNUAL_FUND,
    GET_ALL_PUBLIC_FUND_RAISING
} from "./actionTypes";

const initialState = {
    annual_fund: [],
    fund_raising:[],

};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_HUDAWI_ANNUAL_FUND:
            return { ...state, annual_fund: action.payload };
            case GET_ALL_PUBLIC_FUND_RAISING:
            return { ...state, fund_raising: action.payload };
        default:
            return state;
    }
}