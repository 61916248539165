import React, { Component } from 'react'
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { CgDanger } from 'react-icons/cg';
import { connect } from 'react-redux'
import Loading from '../../../../elements/Loading';
import OtpInput from 'react-otp-input'
import { forgetPassword, verifyOtp, resetPassword } from '../../../../redux/user/action';
import BackButtonHeader from '../../../../elements/BackButtonHeader';
import { message } from 'antd';

class ForgetPassword extends Component {
    state = {
        loading: false,
        email: '',
        phoneError: false,
        invalid_otp: false,
        invalid_password: false,
        Otp: '',
        otp_send: true,
        otp_enter: false,
        otp_success: false,
        password: '',
        confirmPassword: '',
        message:'',
    }
    
    __handleInputPhone = event => {
        this.setState({ email: event.target.value });
    };

    __handleInputDhNumber = event => {
        this.setState({ dh_no: event.target.value });
    };

    _sendPhoneDh = (e) => {
        this.setState({ loading: true });
        e.preventDefault();
        this.props.forgetPassword(this.state.email)
            .then((response) => {
                if (response && response.payload.data.success ) {
                    this.setState({ loading: false,otp_enter:true,otp_send:false});
                }
                else if(response && response.payload.data && !response.payload.data.success){
                    this.setState({loading:false,message:response.payload.data.message})
                    setTimeout(()=>{
                        this.setState({message:''})
                    },3000)
                }
            });
    };

    __handleInputOtp = (Otp) => {
        this.setState({ Otp });
    };

    handleVerifyOtp = (event) => {
        this.setState({ loading: true, });
        event.preventDefault();
        this.props.verifyOtp(this.state.Otp, this.state.email)
            .then((response) => {
                if (response && response.payload && response.payload.data.success) {
                    this.setState({ loading: false, otp_success: true,otp_enter:false});
                }
                else if (response && response.payload && !response.payload.data.success){
                    this.setState({ loading: false,message:response.payload.data.message});
                    setTimeout(()=>{
                        this.setState({message:''})
                    },3000)
                }
            });
    };

    __handleInputPasswordOne = (event) => {
        this.setState({ password: event.target.value });
    };

    __handleInputPasswordTwo = (event) => {
        this.setState({ confirmPassword: event.target.value });
    };

    _resetPassword = (e) => {
        e.preventDefault();
        if(this.state.password == this.state.confirmPassword){
            this.setState({ loading: true });
            this.props.resetPassword(this.state.password, this.state.confirmPassword, this.state.email)
                .then((response) => {
                    if (response && response.payload.data.success === true) {
                        this.setState({ loading: false });
                        window.location.replace("/login");
                    } 
                });
        }else{
            this.setState({invalid_password:true})
            setTimeout(()=>{
               this.setState({invalid_password:false})
            },3000)
        }
    };


    // componentWillReceiveProps(nextProps) {

    //     const { user, forget, verify, reset } = this.props;

    //     if (user !== nextProps.user) {
    //         this.setState({ loading: false });
    //     }

    //     if (nextProps.forget && nextProps.forget.data && nextProps.forget.data.success === true) {
    //         this.setState({ otp_send: false, otp_enter: true, otp_success: false });
    //     } else {
    //         this.setState({ phoneError: true });
    //     }

    //     if (nextProps.verify && nextProps.verify.data && nextProps.verify.data.success === true) {
    //         this.setState({ otp_send: false, otp_enter: false, otp_success: true });
    //     } else {
    //         this.setState({ invalid_otp: true });
    //     }

    //     if (nextProps.reset && nextProps.reset.data && nextProps.reset.data.success === true) {
    //         this.setState({ otp_send: false, otp_enter: false, });

    //     } else {
    //         this.setState({ invalid_password: true });
    //     }


    // }
    render() {
        const { forget, verify } = this.props;
        return (
            <React.Fragment>
                {!this.state.loading ? 
                  (
                     <>
                      <BackButtonHeader title="Forget Password" />
                      {this.state.otp_send &&
                          <div className='d-flex flex-column justify-content-center' style={{ marginBottom: '10vw', marginTop: '10vw' }}>
                              <div>
                                  <Form onSubmit={(e) => this._sendPhoneDh(e)}>
                                      <Form.Group className='px-3'>
                                          <InputGroup className="mb-2">
                                              <FormControl
                                                  type="email"
                                                  placeholder="Enter your registered email"
                                                  autoComplete=""
                                                  autoFocus
                                                  onChange={event =>
                                                      this.__handleInputPhone(event)}
                                                  style={{
                                                      height: "60px",
                                                      color: "#000000",
                                                      border: "0.01 solid #00000025",
                                                      outline: "none",
                                                      backgroundColor: "#ffffff",
                                                      borderRadius: "10px 10px 10px 10px"
                                                  }}
                                                  aria-label="Phone"
                                                  required
                                                  aria-describedby="basic-addon1"
                                              />
                                          </InputGroup>
                                      <span className='text-danger py-2'>{this.state.message}</span>
                                      </Form.Group>
                                      
                                     
                                      <Form.Group className='text-center position-relative' style={{ marginTop: '5vw' }}>
                                          <Button
                                              type="submit"
                                              className="text-white fs-5"
                                              style={{
                                                  backgroundColor: "#035499",
                                                  border: "none",
                                                  outline: "none",
                                                  height: "60px",
                                                  width: "90%",
                                                  borderRadius: '12px',
                                                  fontWeight: '500'
      
                                              }}
                                              variant="warning"
                                              size="lg"
                                          >
                                              Send OTP 
                                          </Button>
      
                                      </Form.Group>
                                  </Form>
                              </div>
                          </div>
                      }
                      {this.state.otp_enter &&
                          <React.Fragment>
                              <div>
                                  <div className='text-center' style={{ marginTop: '15vw' }}>
                                      <span style={{ color: '#0A4866' }}>Enter OTP , We sent to your Email</span>
                                  </div>
                                  <div className='text-center '>
                                      <form onSubmit={this.handleVerifyOtp}>
                                          <div className=' d-flex flex-row justify-content-evenly align-items-center align-content-center'
                                              style={{ marginTop: '30vw' }} >
                                              <OtpInput
                                                  value={this.state.Otp}
                                                  onChange={this.__handleInputOtp}
                                                  numInputs={4}
                                                  separator={<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>}
                                                  isInputNum={true}
                                                  inputStyle={{ height: '12vw', width: '12vw', border: '.01px solid #00000030', backgroundColor: '#ffffff', outline: 'none', borderRadius: '10px', color: '#000', fontSize: '1.5em', fontWeight: '500' }}
                                                  focusStyle={true}
                                                  shouldAutoFocus={true}
                                                  required
                                              />
                                          </div>
                                          
                                              <div className='px-3'>
                                                <span>{this.state.message}</span>
                                              </div>
                                          
      
                                          <div className='text-center btn-fixed' style={{ marginTop: '110vw' }}>
                                              <Button
                                                  type="submit"
                                                  className="text-white fs-5"
                                                  style={{
                                                      backgroundColor: "#035499",
                                                      height: "60px",
                                                      width: "90%",
                                                      borderRadius: '12px',
                                                      fontWeight: '500'
                                                  }}
                                                  variant="warning"
                                                  size="lg"
                                              >Continue</Button>
                                          </div>
                                      </form>
                                  </div>
                              </div>
                              
                          </React.Fragment>
                      }
                      {
                          this.state.otp_success &&
                          <React.Fragment>
                              <div className='d-flex flex-column justify-content-center' style={{ marginBottom: '10vw', marginTop: '10vw' }}>
                                  <div>
                                      <Form onSubmit={(e) => this._resetPassword(e)}>
                                          <Form.Group className='px-3'>
                                              <InputGroup className="mb-3">
                                                  <FormControl
                                                      type="password"
                                                      placeholder="Password"
                                                      minLength="8"
                                                      autoFocus
                                                      // pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$'
                                                      onChange={event =>
                                                          this.__handleInputPasswordOne(event)}
                                                      style={{
                                                          height: "60px",
                                                          color: "#000000",
                                                          border: "0.01 solid #00000025",
                                                          outline: "none",
                                                          backgroundColor: "#ffffff",
                                                          borderRadius: "10px 10px 10px 10px"
                                                      }}
                                                      aria-label="Password"
                                                      required
                                                      aria-describedby="basic-addon1"
                                                  />
                                              </InputGroup>
                                          </Form.Group>
                                          <Form.Group className='mt-2 px-3'>
                                              <InputGroup className="mb-3">
                                                  <FormControl
                                                      type="password"
                                                      placeholder="Confirm Password"
                                                      minLength="8"
                                                      // autoFocus
                                                      // pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$'
                                                      onChange={event =>
                                                          this.__handleInputPasswordTwo(event)}
                                                      style={{
                                                          height: "60px",
                                                          color: "#000000",
                                                          border: "0.01 solid #00000025",
                                                          outline: "none",
                                                          backgroundColor: "#ffffff",
                                                          borderRadius: "10px 10px 10px 10px"
                                                      }}
                                                      aria-label="Confirm Password"
                                                      required
                                                      aria-describedby="basic-addon1"
                                                  />
                                              </InputGroup>
                                              {this.state.invalid_password &&
                                                  <React.Fragment>
                                                      <CgDanger className="text-danger" />
                                                      <small className="text-danger">TWO PASSWORD NOT MATCHING</small>
                                                  </React.Fragment>
                                              }
                                          </Form.Group>
                                          <Form.Group className='text-center position-relative' style={{ marginTop: '15vw' }}>
                                              <Button
                                                  type="submit"
                                                  className="text-white fs-5"
                                                  style={{
                                                      backgroundColor: "#035499",
                                                      border: "none",
                                                      outline: "none",
                                                      height: "60px",
                                                      width: "90%",
                                                      borderRadius: '12px',
                                                      fontWeight: '500'
      
                                                  }}
                                                  variant="warning"
                                                  size="lg"
                                              >
                                                  Submit
                                              </Button>
      
                                          </Form.Group>
                                      </Form>
                                  </div>
      
                              </div>
                          </React.Fragment>
                      }
                      </>
                  )
                  
                  :
                  (
                    <Loading/>
                  )
                }
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    forget: state.user.forget,
    verify: state.user.verify,
    reset: state.user.reset,
})

export default connect(mapStateToProps, {
    forgetPassword, verifyOtp, resetPassword
})(ForgetPassword)