export const LOGIN_EMAIL_PASSWORD = "LOGIN_EMAIL_PASSWORD";
export const VERIFY_OTP = "VERIFY_OTP";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const REGISTER_NEW_USER = "REGISTER_NEW_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const GET_USER_LOAN = "GET_USER_LOAN";
export const GET_PERSONAL_INFO = "GET_PERSONAL_INFO";
export const GET_PUBLICATION_AND_PROJECTS = "GET_PUBLICATION_AND_PROJECTS_URL";
export const UPDATE_PUBLICATION_AND_PROJECTS = "UPDATE_PUBLICATION_AND_PROJECTS_URL";
export const GET_ACHIEVEMENTS_AND_AWARDS = "GET_ACHIEVEMENTS_AND_AWARDS";
export const UPDATE_ACHIEVEMENTS_AND_AWARDS = "UPDATE_ACHIEVEMENTS_AND_AWARDS";
export const GET_USER_ADDRESS = "GET_USER_ADDRESS";
export const GET_USER_CAREER_PLAN = "GET_USER_CAREER_PLAN"
export const GET_USER_ROLES = "GET_USER_ROLES"
export const ADD_OR_UPDATE_ROLES = "ADD_OR_UPDATE_ROLES"
export const GET_USER_LANGUAGE_SKILL = "GET_USER_LANGUAGE_SKILL"
export const GET_USER_EDUCATION_DATA = "GET_USER_EDUCATION_DATA"
export const GET_USER_FAMILY_DETAILS = "GET_USER_FAMILY_DETAILS"
export const GET_USER_EXPERIANCE = "GET_USER_EXPERIANCE"
export const GET_USER_FREELANCE_SERVICES = "GET_USER_FREELANCE_SERVICES"
export const GET_USER_CONTACT_DETAILS = "GET_USER_CONTACT_DETAILS"
export const GET_USER_LOOKING_FOR_JOBES = "GET_USER_LOOKING_FOR_JOBES"
export const GET_USER_SKILL = 'GET_USER_SKILL'
export const GET_USER_PRIVATE_DATA = "GET_USER_PRIVATE_DATA"
export const DELETE_USER = "DELETE_USER";
export const GET_EMPLOYED_DETAILS = "GET_EMPLOYED_DETAILS";