import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Loadable from "react-loadable";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import ForgetPassword from './components/Mobile/Login/ForgetPassword';
import { Loading } from './elements/Loading';
import Test from './components/Mobile/Test/Test';


const GetStarted = Loadable({
  loader: () => import("./components/Mobile/GetStarted/index"),
  loading: () => <Loading />,
});
const UserDetails = Loadable({
  loader: () => import("./components/Mobile/UserDetails/index"),
  loading: () => <Loading />,
});
const Login = Loadable({
  loader: () => import("./components/Mobile/Login"),
  loading: () => <Loading />,
});
const Register = Loadable({
  loader: () => import("./components/Mobile/Register"),
  loading: () => <Loading />,
});
const Home = Loadable({
  loader: () => import("./components/Mobile/Home"),
  loading: () => <Loading />,
});
const Gallery = Loadable({
  loader: () => import("./components/Mobile/Home/ImageGallery"),
  loading: () => <Loading />,
});
const Profile = Loadable({
  loader: () => import("./components/Mobile/Profile/index"),
  loading: () => <Loading />,
});
const PersonalInfo = Loadable({
  loader: () => import("./components/Mobile/Profile/PersonalInfo/index"),
  loading: () => <Loading />,
});
const EditProfile = Loadable({
  loader: () => import("./components/Mobile/Profile/EditProfile/index"),
  loading: () => <Loading />,
});
const MyDonations = Loadable({
  loader: () => import("./components/Mobile/Profile/MyDonations"),
  loading: () => <Loading />,
});
const Jobs = Loadable({
  loader: () => import("./components/Mobile/Jobs/index"),
  loading: () => <Loading />,
});
const JobView = Loadable({
  loader: () => import("./components/Mobile/Jobs/JobView/index"),
  loading: () => <Loading />,
});
const ApplyJob = Loadable({
  loader: () => import("./components/Mobile/Jobs/JobView/ApplyJob/index"),
  loading: () => <Loading />,
});
const Aapas = Loadable({
  loader: () => import("./components/Mobile/Aapas/index"),
  loading: () => <Loading />,
});
const ApplyForLoan = Loadable({
  loader: () => import("./components/Mobile/Aapas/ApplyForLoan/index"),
  loading: () => <Loading />,
});
const SavingDeposit = Loadable({
  loader: () => import("./components/Mobile/Aapas/SavingDeposit/index"),
  loading: () => <Loading />,
});
const ReccurringDeposit = Loadable({
  loader: () => import("./components/Mobile/Aapas/ReccurringDeposit"),
  loading: () => <Loading />,
});
const FixedDeposit = Loadable({
  loader: () => import("./components/Mobile/Aapas/FixedDeposit/index"),
  loading: () => <Loading />,
});
const SingleFixedDeposit = Loadable({
  loader: () => import("./components/Mobile/Aapas/FixedDeposit/SingleFixedDeposit"),
  loading: () => <Loading />,
});
const Investment = Loadable({
  loader: () => import("./components/Mobile/Aapas/Investment/index"),
  loading: () => <Loading />,
});
const Payments = Loadable({
  loader: () => import("./components/Mobile/MyPayments/index"),
  loading: () => <Loading />,
});
const PaymentDonation = Loadable({
  loader: () => import("./components/Mobile/MyPayments/MyDonation"),
  loading: () => <Loading />,
});
const PaymentDonationSuccess = Loadable({
  loader: () => import("./components/Mobile/MyPayments/MyDonation/PaymentDonationSuccess"),
  loading: () => <Loading />,
});
const HudawiAnnualFund = Loadable({
  loader: () => import("./components/Mobile/MyPayments/HudawiAnnualFund"),
  loading: () => <Loading />,
});
const PaymentHafSuccess = Loadable({
  loader: () => import("./components/Mobile/MyPayments/HudawiAnnualFund/PaymentHafSuccess"),
  loading: () => <Loading />,
});
const HadiaMembership = Loadable({
  loader: () => import("./components/Mobile/MyPayments/HadiaMembership"),
  loading: () => <Loading />,
});
const PaymentMembershipSuccess = Loadable({
  loader: () => import("./components/Mobile/MyPayments/HadiaMembership/PaymentMembershipSuccess"),
  loading: () => <Loading />,
});
const PublicFundRising = Loadable({
  loader: () => import("./components/Mobile/MyPayments/PublicFundRising"),
  loading: () => <Loading />,
});
const FundRaisingDonatePage = Loadable({
  loader: () => import("./components/Mobile/MyPayments/PublicFundRising/FundRaisingDonatePage"),
  loading: () => <Loading />,
});
const FundRaisingPaymentSuccess = Loadable({
  loader: () => import("./components/Mobile/MyPayments/PublicFundRising/FundRaisingPaymentSuccess"),
  loading: () => <Loading />,
});
const RecordIndirectPayments = Loadable({
  loader: () => import("./components/Mobile/MyPayments/PublicFundRising/RecordIndirectPayments"),
  loading: () => <Loading />,
});
const MyZakat = Loadable({
  loader: () => import("./components/Mobile/MyPayments/MyZakat"),
  loading: () => <Loading />,
});
const ZakathPaymentSuccess = Loadable({
  loader: () => import("./components/Mobile/MyPayments/MyZakat/ZakathPaymentSuccess"),
  loading: () => <Loading />,
});
const UpdateUserInfo = Loadable({
  loader: () => import("./components/Mobile/UpdateUserInfo"),
  loading: () => <Loading />,
});
const TransactionLog = Loadable({
  loader: () => import("./components/Mobile/TransactionLog"),
  loading: () => <Loading />,
});
const AllCourses = Loadable({
  loader: () => import("./components/Mobile/Courses/AllCourses"),
  loading: () => <Loading />,
});
const SingleCourse = Loadable({
  loader: () => import("./components/Mobile/Courses/SingleCourse"),
  loading: () => <Loading />,
});
const AllCommitte = Loadable({
  loader: () => import("./components/Mobile/Committee/AllCommitte"),
  loading: () => <Loading />,
});
const SingleCommitte = Loadable({
  loader: () => import("./components/Mobile/Committee/SingleCommitte"),
  loading: () => <Loading />,
});
const AllDepartments = Loadable({
  loader: () => import("./components/Mobile/Departments/AllDepartments"),
  loading: () => <Loading />,
});
const SingleDepartment = Loadable({
  loader: () => import("./components/Mobile/Departments/SingleDepartment"),
  loading: () => <Loading />,
});

const JoinAapas = Loadable({
  loader: () => import("./components/Mobile/Aapas/JoinAapas"),
  loading: () => <Loading />,
});
const Circulars = Loadable({
  loader: () => import("./components/Mobile/Circulars"),
  loading: () => <Loading />,
})
const Downloads = Loadable({
  loader: () => import("./components/Mobile/Downloads"),
  loading: () => <Loading />,
})
const UseFullLinks = Loadable({
  loader: () => import("./components/Mobile/UseFullLinks"),
  loading: () => <Loading />,
})

const Projects = Loadable({
  loader: () => import("./components/Mobile/Projects"),
  loading: () => <Loading />,
})

const SingleProjects = Loadable({
  loader: () => import("./components/Mobile/Projects/SingleProjects"),
  loading: () => <Loading />,
})

const ContactUs = Loadable({
  loader: () => import("./components/Mobile/ContactUs"),
  loading: () => <Loading />,
})

const GrevienceRequest = Loadable({
  loader: () => import("./components/Mobile/Greviance/GrevienceRequest"),
  loading: () => <Loading />,
})

const WelfareRequest = Loadable({
  loader: () => import("./components/Mobile/Welfare/WelfareRequest"),
  loading: () => <Loading />,
})
const HudawiEmpowermentCell = Loadable({
  loader: () => import("./components/Mobile/HudawiEmpowermentCell"),
  loading: () => <Loading />,
})
const AboutUs = Loadable({
  loader: () => import("./components/Mobile/AboutUs"),
  loading: () => <Loading />,
})

const HadiaHelp = Loadable({
  loader: () => import("./components/Mobile/HadiaHelp"),
  loading: () => <Loading />,
})

const MyGreviance = Loadable({
  loader: () => import("./components/Mobile/Profile/MyGreviance"),
  loading: () => <Loading />,
})
const Skills = Loadable({
  loader: () => import("./components/Mobile/UpdateUserInfo/Skills"),
  loading: () => <Loading />,
})

const PrivateUserData = Loadable({
  loader: () => import("./components/Mobile/PrivateUserData"),
  loading: () => <Loading />,
})
const Settings = Loadable({
  loader: () => import("./components/Mobile/Settings"),
  loading: () => <Loading />,
})
const ResetPassword = Loadable({
  loader: () => import("./components/Mobile/Settings/ResetPassword"),
  loading: () => <Loading />,
})
const SearchHudawiJob = Loadable({
  loader: () => import("./components/Mobile/SearchHudawiJob"),
  loading: () => <Loading />,
})
const SearchSingleUser = Loadable({
  loader: () => import("./components/Mobile/SearchSingleUser"),
  loading: () => <Loading />,
})
const FreelanceRequest = Loadable({
  loader: () => import("./components/Mobile/FreelanceRequest"),
  loading: () => <Loading />,
})
const SingleFreelance = Loadable({
  loader: () => import("./components/Mobile/SingleFreelance"),
  loading: () => <Loading />,
})
const SinglePublicFreelance = Loadable({
  loader: () => import("./components/Mobile/SingleFreelance/SinglePublicFreelance"),
  loading: () => <Loading />,
})
const TermsAndConditions = Loadable({
  loader: () => import("./components/Mobile/TermsAndConditions"),
  loading: () => <Loading />,
})

const PrivacyAndPolicy = Loadable({
  loader: () => import("./components/Mobile/PrivacyAndPolicy"),
  loading: () => <Loading />,
})
const ReturnAndPolicy = Loadable({
  loader: () => import("./components/Mobile/ReturnAndPolicy"),
  loading: () => <Loading />,
})

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

  <Root>

    {/* <React.StrictMode> */}

    <BrowserRouter>
      <Route component={ScrollToTop} />

      <Switch>
        <Route exact path="/" component={withRouter(GetStarted)} />
        <Route exact path="/test" component={withRouter(Test)} />
        <Route exact path="/login" component={withRouter(Login)} />
        <Route exact path="/home" component={withRouter(Home)} />
        <Route exact path="/view-gallery/:id" component={withRouter(Gallery)} />
        <Route exact path="/forget-password" component={withRouter(ForgetPassword)} />
        <Route exact path="/sign-up" component={withRouter(Register)} />
        <Route exact path="/profile" component={withRouter(Profile)} />
        <Route exact path="/personal-info" component={withRouter(PersonalInfo)} />
        <Route exact path="/edit-profile" component={withRouter(EditProfile)} />
        <Route exact path="/my-donations" component={withRouter(MyDonations)} />
        <Route exact path="/jobs" component={withRouter(Jobs)} />
        <Route exact path="/job-view/:id" component={withRouter(JobView)} />
        <Route exact path="/apply-job/:id" component={withRouter(ApplyJob)} />
        <Route exact path="/aapas" component={withRouter(Aapas)} />
        <Route exact path="/join-aapas" component={withRouter(JoinAapas)} />
        <Route exact path="/payments" component={withRouter(Payments)} />
        <Route exact path="/payment-donation-success/:id" component={withRouter(PaymentDonationSuccess)} />
        <Route exact path="/hudawi-annual-fund" component={withRouter(HudawiAnnualFund)} />
        <Route exact path="/payment-haf-success/:id" component={withRouter(PaymentHafSuccess)} />
        <Route exact path="/payment-donation" component={withRouter(PaymentDonation)} />
        <Route exact path="/hadia-membership" component={withRouter(HadiaMembership)} />
        <Route exact path="/payment-membership-success/:id" component={withRouter(PaymentMembershipSuccess)} />
        <Route exact path="/public-fund-rising" component={withRouter(PublicFundRising)} />
        <Route exact path="/payment-public-fund-donate/:id" component={withRouter(FundRaisingDonatePage)} />
        <Route exact path="/payment-public-fund-success/:id" component={withRouter(FundRaisingPaymentSuccess)} />
        <Route exact path="/record-indirect-payment/:id" component={withRouter(RecordIndirectPayments)} />
        <Route exact path="/my-zakat" component={withRouter(MyZakat)} />
        <Route exact path="/payment-zakath-success/:id" component={withRouter(ZakathPaymentSuccess)} />
        <Route exact path="/user-details" component={withRouter(UserDetails)} />
        <Route exact path="/update-user-info/:id" component={withRouter(UpdateUserInfo)} />
        <Route exact path="/investment" component={withRouter(Investment)} />
        <Route exact path="/apply-for-loan/:id" component={withRouter(ApplyForLoan)} />
        <Route exact path="/saving-deposit" component={withRouter(SavingDeposit)} />
        <Route exact path="/transaction-log/:type" component={withRouter(TransactionLog)} />
        <Route exact path="/fixed-deposit" component={withRouter(FixedDeposit)} />
        <Route exact path="/reccurring-deposit" component={withRouter(ReccurringDeposit)} />
        <Route exact path="/single-fixed-deposit/:id" component={withRouter(SingleFixedDeposit)} />
        <Route exact path="/all-courses" component={withRouter(AllCourses)} />
        <Route exact path="/single-course/:id" component={withRouter(SingleCourse)} />
        <Route exact path="/all-committee" component={withRouter(AllCommitte)} />
        <Route exact path="/single-committee/:id" component={withRouter(SingleCommitte)} />
        <Route exact path="/all-department" component={withRouter(AllDepartments)} />
        <Route exact path="/single-department/:id" component={withRouter(SingleDepartment)} />
        <Route exact path="/all-circulars" component={withRouter(Circulars)} />
        <Route exact path="/all-downloads" component={withRouter(Downloads)} />
        <Route exact path="/all-links" component={withRouter(UseFullLinks)} />
        <Route exact path="/all-projects" component={withRouter(Projects)} />
        <Route exact path="/get-single-projects/:id/:type" component={withRouter(SingleProjects)} />
        <Route exact path="/contact-us" component={withRouter(ContactUs)} />
        <Route exact path="/greviance-request" component={withRouter(GrevienceRequest)} />
        <Route exact path="/welfare-request" component={withRouter(WelfareRequest)} />
        <Route exact path="/hudawi-wmpowerment-cells" component={withRouter(HudawiEmpowermentCell)} />
        <Route exact path="/about-us" component={withRouter(AboutUs)} />
        <Route exact path="/hadia-help" component={withRouter(HadiaHelp)} />
        <Route exact path="/my-greviance" component={withRouter(MyGreviance)} />
        <Route exact path="/my-skills" component={withRouter(Skills)} />
        <Route exact path="/user-private-data" component={withRouter(PrivateUserData)} />
        <Route exact path="/settings" component={withRouter(Settings)} />
        <Route exact path="/reset-password" component={withRouter(ResetPassword)} />
        <Route exact path="/search-users-and-jobs" component={withRouter(SearchHudawiJob)} />
        <Route exact path="/search-single-user/:id" component={withRouter(SearchSingleUser)} />
        <Route exact path="/freelance-request/:id" component={withRouter(FreelanceRequest)} />
        <Route exact path="/single-freelance/:id" component={withRouter(SingleFreelance)} />
        <Route exact path="/single-public-freelance/:id" component={withRouter(SinglePublicFreelance)} />
        <Route exact path="/terms-and-conditions" component={withRouter(TermsAndConditions)} />
        <Route exact path="/privacy-and-policy" component={withRouter(PrivacyAndPolicy)} />
        <Route exact path="/return-and-policy" component={withRouter(ReturnAndPolicy)} />

      </Switch>
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </Root>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
