import {
    GET_COURSE,
    GET_JOBS,
    GET_PROJECT_CATEGORIES,
    GET_STATE_AND_DISTRICT,
    GET_ANNUAL_PACKAGE,
    GET_LANGUAGE,
    GET_FREELANCE_SERVICE_TYPE,
    GET_SKILLS,
    ALL_DEPARTMENTS,
    ALL_COMMITTEE,
    GET_CELLS,
    GET_UNIVERSITIES,
    GET_INSTITUTE,
    GET_PANCHAYATH_DETAILS

} from "./actionTypes";

const initialState = {
    courses: [],
    jobTitle: [],
    jobCategory: [],
    projectCategories: [],
    state: [],
    district: [],
    annual_package: [],
    languages: [],
    freelanceType: [],
    skills: [],
    committee: [],
    departments: [],
    cells: [],
    universities: [],
    institutes: [],
    panchayath: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    // console.log(action)
    switch (action.type) {
        case GET_COURSE:
            return { ...state, courses: action.payload };
        case GET_JOBS:
            return { ...state, jobTitle: action.payload.job_title, jobCategory: action.payload.job_category };
        case GET_PROJECT_CATEGORIES:
            return { ...state, projectCategories: action.payload };
        case GET_STATE_AND_DISTRICT:
            return { ...state, state: action.payload.states, district: action.payload.districts };
        case GET_ANNUAL_PACKAGE:
            return { ...state, annual_package: action.payload };
        case GET_UNIVERSITIES:
            return { ...state, universities: action.payload };
        case GET_INSTITUTE:
            return { ...state, institutes: action.payload };
        case GET_LANGUAGE:
            return { ...state, languages: action.payload };
        case GET_FREELANCE_SERVICE_TYPE:
            return { ...state, freelanceType: action.payload };
        case GET_SKILLS:
            return { ...state, skills: action.payload };
        case ALL_COMMITTEE:
            return { ...state, committee: action.payload };
        case ALL_DEPARTMENTS:
            return { ...state, departments: action.payload };
        case GET_CELLS:
            return { ...state, cells: action.payload };
        case GET_PANCHAYATH_DETAILS:
            return { ...state, panchayath: action.payload }
        default:
            return state;
    }
}
