import Axios, { AxiosError } from "axios";
import {
    LOGIN_EMAIL_PASSWORD_URL,
    FORGET_PASSWORD_URL,
    VERIFY_OTP_URL,
    RESET_PASSWORD_URL,
    REGISTER_NEW_USER_URL,
    UPDATE_USER_INFO_URL,
    GET_USER_LOAN_URL,
    GET_PERSONAL_INFO_URL,
    UPDATE_PERSONAL_INFO_URL,
    GET_PUBLICATION_AND_PROJECTS_URL,
    UPDATE_PROJECTS_AND_PUBLICATION_URL,
    GET_ACHIEVEMENTS_AND_AWARDS_URL,
    UPDATE_ACHIEVEMENTS_AND_AWARDS_URL,
    GET_USER_ADDRESS_URL,
    UPDATE_USER_ADDRESS_URL,
    GET_USER_CAREER_PLAN_URL,
    UPDATE_USER_CAREER_PLAN_URL,
    GET_USER_ROLES_URL,
    ADD_OR_UPDATE_ROLES_URL,
    GET_USER_LANGUAGE_SKILL_URL,
    GET_USER_EDUCATION_DATA_URL,
    ADD_OR_UPDATE_HIGH_SCHOOL_URL,
    ADD_OR_UPDATE_GRADUATION_URL,
    ADD_OR_UPDATE_MPHIL_OR_PHD_URL,
    GET_USER_FAMILY_DETAILS_URL,
    UPDATE_USER_FAMILY_DETAILS_URL,
    UPDATE_USER_CHILDRENS_DETAILS_URL,
    GET_USER_EXPERIANCE_URL,
    UPDATE_USER_EXPERIANCE_URL,
    UPDATE_USER_LANGUAGE_SKILL_URL,
    GET_USER_FREELANCE_SERVICES_URL,
    UPDATE_USER_FREELANCE_SERVICES_URL,
    GET_USER_CONTACT_DETAILS_URL,
    UPDATE_USER_CONTACT_DETAILS_URL,
    GET_EMPLOYED_DETAILS_URL,
    ADD_OR_UPDATE_EMPLOYED_DETAILS_URL,
    GET_STUDYING_DETAILS_URL,
    UPDATE_STUDYING_DETAILS_URL,
    GET_USER_LOOKING_FOR_JOBES_URL,
    UPDATE_USER_LOOKING_FOR_JOBES_URL,
    UPDATE_USER_FREELANCE_STATUS_URL,
    GET_HELP_URL,
    GET_USER_SKILL_URL,
    UPDATE_USER_SKILL_URL,
    UPDATE_USER_PRIVATE_DATA_URL,
    GET_USER_PRIVATE_DATA_URL,
    USER_LAST_VISITED_DATE_URL,
    UPDATE_PASSWORD_URL,
    UPDATE_FREELANCE_REQUEST_URL,
    DELETE_USER_URL



} from '../../api/index.js';

import {
    LOGIN_EMAIL_PASSWORD,
    FORGET_PASSWORD,
    VERIFY_OTP,
    RESET_PASSWORD,
    REGISTER_NEW_USER,
    LOGOUT_USER,
    UPDATE_USER_INFO,
    GET_USER_LOAN,
    GET_PERSONAL_INFO,
    UPDATE_PERSONAL_INFO,
    GET_PUBLICATION_AND_PROJECTS,
    UPDATE_PUBLICATION_AND_PROJECTS,
    GET_ACHIEVEMENTS_AND_AWARDS,
    UPDATE_ACHIEVEMENTS_AND_AWARDS,
    GET_USER_ADDRESS,
    GET_USER_CAREER_PLAN,
    GET_USER_ROLES,
    ADD_OR_UPDATE_ROLES,
    GET_USER_LANGUAGE_SKILL,
    GET_USER_EDUCATION_DATA,
    GET_USER_FAMILY_DETAILS,
    GET_USER_EXPERIANCE,
    GET_USER_FREELANCE_SERVICES,
    GET_USER_CONTACT_DETAILS,
    GET_USER_LOOKING_FOR_JOBES,
    GET_USER_SKILL,
    UPDATE_USER_PRIVATE_DATA,
    GET_USER_PRIVATE_DATA,
    UPDATE_PASSWORD,
    DELETE_USER,
    GET_EMPLOYED_DETAILS

} from './actionTypes';

export const loginEmailPassword = (email, password, login_token) => (dispatch) => {
    return Axios.post(LOGIN_EMAIL_PASSWORD_URL, {
        email, password, login_token
    })
        .then((response) => {
            const user = response.data;
            // console.log(user);
            return dispatch({ type: LOGIN_EMAIL_PASSWORD, payload: user });

        })
        .catch(function (error) {
            console.log(error);
        });
};
export const signUp = (formData) => (dispatch) => {
    return Axios.post(REGISTER_NEW_USER_URL, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }
    ).then((response) => {
        // console.log(response.data)
        return dispatch({ type: REGISTER_NEW_USER, payload: response.data })
    }).catch((error) => {
        console.log(error)
    })
}
export const forgetPassword = (email) => (dispatch) => {
    return Axios.post(FORGET_PASSWORD_URL, {
        email
    })
        .then((response) => {
            const forget = response;
            // console.log(forget);
            return dispatch({ type: FORGET_PASSWORD, payload: forget });

        })
        .catch(function (error) {
            console.log(error);
        });
};

export const verifyOtp = (otp, email) => (dispatch) => {
    return Axios.post(VERIFY_OTP_URL, {
        otp, email
    })
        .then((response) => {
            const verify = response;
            // console.log(verify);
            return dispatch({ type: VERIFY_OTP, payload: verify });

        })
        .catch(function (error) {
            console.log(error);
        });
};

export const resetPassword = (password, confirmPassword, email) => (dispatch) => {
    return Axios.post(RESET_PASSWORD_URL, {
        password, confirmPassword, email
    })
        .then((response) => {
            const reset = response;
            // console.log(reset);
            return dispatch({ type: RESET_PASSWORD, payload: reset });

        })
        .catch(function (error) {
            console.log(error);
        });
};

export const updatePassword = (token, currentPassword, newPassword, confirmPassword) => (dispatch) => {
    return Axios.post(UPDATE_PASSWORD_URL, {
        token, currentPassword, newPassword, confirmPassword
    })
        .then((response) => {
            const reset = response;
            console.log(reset);
            return dispatch({ type: UPDATE_PASSWORD, payload: reset });

        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getUserLoan = (token) => (dispatch) => {
    return Axios.post(GET_USER_LOAN_URL, {
        token
    })
        .then((response) => {
            const user = response.data;
            // console.log(user);
            return dispatch({ type: GET_USER_LOAN, payload: user });

        })
        .catch(function (error) {
            console.log(error);
        });
};

export const updateUserInfo = (token) => (dispatch) => {
    return Axios.post(UPDATE_USER_INFO_URL, {
        token
    })
        .then((response) => {
            const user = response.data;
            // console.log(user);
            return dispatch({ type: UPDATE_USER_INFO, payload: user });

        })
        .catch(function (error) {
            console.log(error);
        });
};

export const logoutUser = () => (dispatch) => {
    const user = [];
    console.log(user, 'logout action')
    //remove geoLocation and userSetAddress
    // localStorage.removeItem("userSetAddress");
    // localStorage.removeItem("geoLocation");
    // localStorage.removeItem("lastSavedNotificationToken");

    return [
        dispatch({
            type: LOGOUT_USER,
            payload: user,
        }),

    ];
};

export const getOrUpdatePersonalInfo = (auth_token) => (dispatch) => {
    return Axios.post(GET_PERSONAL_INFO_URL, { token: auth_token }).then((response) => {
        let data = response.data
        return dispatch({ type: GET_PERSONAL_INFO, payload: data })
    })
}


export const updatePersonalInfo = (formData) => (dispatch) => {
    return Axios.post(UPDATE_PERSONAL_INFO_URL, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }).then((response) => {
        let data = response.data
        return dispatch({ type: GET_PERSONAL_INFO, payload: data })
    }).catch((error) => {
        // window.location
        // .replace('\login')
        console.log(error)
    })
}


export const getPublicationAndProjects = (token) => (dispatch) => {

    return Axios.post(GET_PUBLICATION_AND_PROJECTS_URL, { token }).then((response) => {
        let data = response.data.projects
        return dispatch({ type: GET_PUBLICATION_AND_PROJECTS, payload: data })
    }).catch((error) => {
        console.log('error')
    })
}
export const updateProjectsAndPublication = (token, data, type) => (dispatch) => {
    return Axios.post(UPDATE_PROJECTS_AND_PUBLICATION_URL, { token, data, type })
}


export const getAchievementAndAwards = (token) => (dispatch) => {
    return Axios.post(GET_ACHIEVEMENTS_AND_AWARDS_URL, { token }).then((response) => {
        let data = response.data.awards
        return dispatch({ type: GET_ACHIEVEMENTS_AND_AWARDS, payload: data });
    })
}

export const updateAchievementAndAwards = (token, data, type) => (dispatch) => {
    return Axios.post(UPDATE_ACHIEVEMENTS_AND_AWARDS_URL, { token, data, type })
}


export const getUserAddress = (token) => (dispatch) => {
    return Axios.post(GET_USER_ADDRESS_URL, { token })
        .then((response) => {
            if (response && response.data.success) {
                let data = response.data
                // console.log(data)
                return dispatch({ type: GET_USER_ADDRESS, payload: data });
            } else {
                window.location.replace("/login")
            }
        })
        .catch(function (error) {
            console.log(error)
        })
}


export const updateUserAddress = (token, currentAddress, homeAddress) => (dispatch) => {
    return Axios.post(UPDATE_USER_ADDRESS_URL, { token, currentAddress, homeAddress })
}


export const getUserCareerPlan = (token) => (dispatch) => {
    return Axios.post(GET_USER_CAREER_PLAN_URL, { token })
        .then((response) => {
            let data = response.data
            return dispatch({ type: GET_USER_CAREER_PLAN, payload: data })
        }).catch((error) => {
            console.log(error)
        })
}

export const updateUserCareerPlan = (token, data, type) => () => {
    return Axios.post(UPDATE_USER_CAREER_PLAN_URL, { token, data, type })
}



export const getUserRoles = (token) => (dispatch) => {
    return Axios.post(GET_USER_ROLES_URL, { token }).then((response) => {
        let data = response.data.userRoles
        return dispatch({ type: GET_USER_ROLES, payload: data })
    })
}

export const addOrUpdateRole = (token, data, type) => (dispatch) => {
    return Axios.post(ADD_OR_UPDATE_ROLES_URL, { token, data, type })
}

export const getLanguageSkill = (token) => (dispatch) => {
    return Axios.post(GET_USER_LANGUAGE_SKILL_URL, { token })
        .then((response) => {
            let data = response.data.languageSkill
            return dispatch({ type: GET_USER_LANGUAGE_SKILL, payload: data })
        })
}

export const updateUserLanguageSkill = (token, data) => (dispatch) => {
    return Axios.post(UPDATE_USER_LANGUAGE_SKILL_URL, { token, data })
}

//get user highschool data

export const getUserEducationData = (token, type) => (dispatch) => {
    return Axios.post(GET_USER_EDUCATION_DATA_URL, { token, type })
        .then((response) => {
            let data = response.data.data
            return dispatch({ type: GET_USER_EDUCATION_DATA, payload: data })
        })
}

export const addOrUpdateHighSchool = (token, data, type) => (dispatch) => {
    return Axios.post(ADD_OR_UPDATE_HIGH_SCHOOL_URL, { token, data, type })
}

export const addOrUpdateGraduation = (token, data, type) => (dispatch) => {
    return Axios.post(ADD_OR_UPDATE_GRADUATION_URL, { token, data, type })
}

export const addOrUpdateMphilOrPhd = (token, data, type) => (dispatch) => {
    return Axios.post(ADD_OR_UPDATE_MPHIL_OR_PHD_URL, { token, data, type })
}

export const getUserFamilDetails = (token) => (dispatch) => {
    return Axios.post(GET_USER_FAMILY_DETAILS_URL, { token })
        .then((response) => {
            let data = response.data
            return dispatch({ type: GET_USER_FAMILY_DETAILS, payload: data })
        })
}

export const updateFamilyDetails = (token, data, spouse) => (dispatch) => {
    return Axios.post(UPDATE_USER_FAMILY_DETAILS_URL, { token, data, spouse })
}

export const updateChildrensDetails = (token, data, type) => (dispatch) => {
    return Axios.post(UPDATE_USER_CHILDRENS_DETAILS_URL, { token, data, type })
}

export const getUserExperiance = (token) => (dispatch) => {
    return Axios.post(GET_USER_EXPERIANCE_URL, { token })
        .then((response) => {
            let data = response.data.data
            return dispatch({ type: GET_USER_EXPERIANCE, payload: data })
        })
}

export const addOrUpdateUserExperiance = (token, data, type) => (dispatch) => {
    return Axios.post(UPDATE_USER_EXPERIANCE_URL, { token, data, type })
}

export const getUserFreelanceServices = (token) => (dispatch) => {
    return Axios.post(GET_USER_FREELANCE_SERVICES_URL, { token })
        .then((response) => {
            let data = response.data.data
            return dispatch({ type: GET_USER_FREELANCE_SERVICES, payload: data })
        })
}

export const addOrUpdateUserFreelance = (token, data, type) => (dispatch) => {
    return Axios.post(UPDATE_USER_FREELANCE_SERVICES_URL, { token, data, type })
}

export const getContactDetails = (token) => (dispatch) => {
    return Axios.post(GET_USER_CONTACT_DETAILS_URL, { token })
        .then((response) => {
            let data = response.data
            return dispatch({ type: GET_USER_CONTACT_DETAILS, payload: data })
        })
}

export const addOrUpdateContactDetails = (formData) => (dispatch) => {
    return Axios.post(UPDATE_USER_CONTACT_DETAILS_URL, formData)
}

export const getUserEmployedDetails = (token) => (dispatch) => {
    return Axios.post(GET_EMPLOYED_DETAILS_URL, { token }).then((response) => {
        let employed_details = response.data.data
        return dispatch({ type: GET_EMPLOYED_DETAILS, payload: employed_details })
    })
}

export const addOrUpdateEmployedDetails = (token, data, type) => (dispatch) => {
    return Axios.post(ADD_OR_UPDATE_EMPLOYED_DETAILS_URL, { token, data, type })
}


export const getUserStudyingDetails = (token) => (dispatch) => {
    return Axios.post(GET_STUDYING_DETAILS_URL, { token })
}

export const updateUserStudyingDetails = (token, data) => (dispatch) => {
    return Axios.post(UPDATE_STUDYING_DETAILS_URL, { token, data })
}

export const getUserLookingForJobes = (token) => (dispatch) => {
    return Axios.post(GET_USER_LOOKING_FOR_JOBES_URL, { token })
        .then((response) => {
            let data = response.data.data
            return dispatch({ type: GET_USER_LOOKING_FOR_JOBES, payload: data })
        })
}

export const addOrUpdateLookinfForJobes = (token, data, type) => (dispatch) => {
    return Axios.post(UPDATE_USER_LOOKING_FOR_JOBES_URL, { token, data, type })
}

export const updateFreelanceStatus = (token, status) => (dispatch) => {
    return Axios.post(UPDATE_USER_FREELANCE_STATUS_URL, { token, status })
}

export const sendHelpRequest = (token, description) => (dispatch) => {
    return Axios.post(GET_HELP_URL, { token, description })
}

export const getMySkills = (token) => (dispatch) => {
    return Axios.post(GET_USER_SKILL_URL, { token })
        .then((response) => {
            let data = response.data.data
            return dispatch({ type: GET_USER_SKILL, payload: data })
        })
}

export const updateMySkill = (token, skill) => (dispatch) => {
    return Axios.post(UPDATE_USER_SKILL_URL, { token, skill })
}

//PRIVATE
export const getUserPrivateData = (token) => (dispatch) => {
    return Axios.post(GET_USER_PRIVATE_DATA_URL, { token })
        .then((response) => {
            let data = response.data.data
            return dispatch({ type: GET_USER_PRIVATE_DATA, payload: data })
        })
}

export const addOrUpdatePrivateData = (formData) => (dispatch) => {
    return Axios.post(UPDATE_USER_PRIVATE_DATA_URL, formData)
}


export const lastVisitedDate = (token, page) => {
    return Axios.post(USER_LAST_VISITED_DATE_URL, { token, page })
}


export const freelanceRequest = (formdata) => (dispatch) => {
    return Axios.post(UPDATE_FREELANCE_REQUEST_URL, formdata, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}
export const deleteUser = (token) => (dispatch) => {
    return Axios.post(DELETE_USER_URL, {
        token,
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: DELETE_USER, payload: user });
        })
        .catch(function (error) {
            console.log(error);
        });


};