/* eslint-disable import/no-anonymous-default-export */
import {
  GET_ALL_JOB_OPENINGS,
  GET_SINGLE_JOB_VIEW,
  GET_ALL_USER_JOB_OPENINGS,
  USER_JOB_APPLY,
  GET_USER_FREELANCE_REQUEST,
  GET_SINGLE_FREELANCE_REQUEST,
  GET_USER__PUBLIC_FREELANCE_REQUEST,
  GET_SINGLE_PUBLIC_FREELANCE_REQUEST,
} from "./actionTypes";

const initialState = {
  user_job_openings: [],
  job_openings: [],
  job_details: [],
  job_request: [],
  freelance_works: [],
  freelance: [],
  public_freelance_works: [],
  public_freelance: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USER_JOB_OPENINGS:
      return { ...state, user_job_openings: action.payload };
    case GET_ALL_JOB_OPENINGS:
      return { ...state, job_openings: action.payload };
    case GET_SINGLE_JOB_VIEW:
      return { ...state, job_details: action.payload };
    case USER_JOB_APPLY:
      return { ...state, job_request: action.payload };
    case GET_USER__PUBLIC_FREELANCE_REQUEST:
      return { ...state, public_freelance_works: action.payload };
    case GET_USER_FREELANCE_REQUEST:
      return { ...state, public_freelance_works: action.payload };
    case GET_SINGLE_FREELANCE_REQUEST:
      return { ...state, freelance: action.payload };
    case GET_SINGLE_PUBLIC_FREELANCE_REQUEST:
      return { ...state, public_freelance: action.payload };
    default:
      return state;
  }
}
