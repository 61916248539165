import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import Ink from 'react-ink';
import { withRouter } from 'react-router-dom';

class BackButton extends Component {

    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <React.Fragment>
                <div className='backbutton-fixed'>
                    <div className='d-flex flex-row align-items-center position-relative' style={{ backgroundColor: '#fff' }}>
                        <div className='flex-shrink-1 mb-2 ms-3 me-3 position-absolute' style={{ left: '0px' }}>
                            <div className='p-1 fw-bold' style={{ borderRadius: '100px', backgroundColor: '#035499' }} onClick={() => this.onHandleBack()}>
                                <Ink style={{ color: '#aaaaaa' }} />
                                <span className='fw-bold'>
                                    <HiOutlineChevronLeft size="2em" color='#fff' />
                                </span>
                            </div>
                        </div>
                        <div className='flex-grow-1 text-white text-center fs-5'>
                            <span className='mt-3'>{this.props.title}</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({

})


export default withRouter(connect(mapStateToProps, {

})(BackButton))