import { combineReducers } from "redux";
import userReducer from '../redux/user/reducer';
import registerReducer from '../redux/register/reducer';
import commonReducer from '../redux/common/reducer';
import HomeReducer from '../redux/home/reducer';
import AapasReducer from '../redux/aapas/reducer';
import JobReducer from '../redux/job/reducer';
import paymentReducer from '../redux/payment/reducer';
import SearchReducer from '../redux/search/reducer';

export default combineReducers({
    user: userReducer,
    register: registerReducer,
    payment: paymentReducer,
    common: commonReducer,
    home: HomeReducer,
    aapas: AapasReducer,
    jobs: JobReducer,
    search: SearchReducer,

});
