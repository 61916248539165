export const GET_ALL_HUDAWI_ANNUAL_FUND = "GET_ALL_HUDAWI_ANNUAL_FUND";
export const GET_ALL_PUBLIC_FUND_RAISING = "GET_ALL_PUBLIC_FUND_RAISING";
export const GET_DONATION_USER = "GET_DONATION_USER";
export const DONATION_PAYMENT_SUCCESS = "DONATION_PAYMENT_SUCCESS";
export const GET_HAF_USER = "GET_HAF_USER";
export const HAF_PAYMENT_SUCCESS = "HAF_PAYMENT_SUCCESS";
export const MEMBERSHIP_PAYMENT_SUCCESS = "MEMBERSHIP_PAYMENT_SUCCESS";
export const GET_MEMBERSHIP_USER = "GET_MEMBERSHIP_USER";
export const GET_PUBLIC_FUND_RAISING_USER = "GET_PUBLIC_FUND_RAISING_USER";
export const PUBLIC_FUND_RAISING_PAYMENT_SUCCESS = "PUBLIC_FUND_RAISING_PAYMENT_SUCCESS";
export const GET_ZAKATH_USER = "GET_ZAKATH_USER";
export const ZAKATH_PAYMENT_SUCCESS = "ZAKATH_PAYMENT_SUCCESS";
export const INDIRECT_PAYMENT_REQUEST = "INDIRECT_PAYMENT_REQUEST";
export const GET_SINGLE_PUBLIC_FUND_RAISING = "GET_SINGLE_PUBLIC_FUND_RAISING";