/* eslint-disable import/no-anonymous-default-export */
import {
    LOGIN_EMAIL_PASSWORD,
    REGISTER_NEW_USER,
    FORGET_PASSWORD,
    VERIFY_OTP,
    RESET_PASSWORD,
    LOGOUT_USER,
    UPDATE_USER_INFO,
    GET_USER_LOAN,
    GET_PERSONAL_INFO,
    GET_PUBLICATION_AND_PROJECTS,
    GET_ACHIEVEMENTS_AND_AWARDS,
    GET_USER_ADDRESS,
    GET_USER_CAREER_PLAN,
    GET_USER_ROLES,
    GET_USER_LANGUAGE_SKILL,
    GET_USER_EDUCATION_DATA,
    GET_USER_FAMILY_DETAILS,
    GET_USER_EXPERIANCE,
    GET_USER_FREELANCE_SERVICES,
    GET_USER_CONTACT_DETAILS,
    GET_USER_LOOKING_FOR_JOBES,
    GET_USER_SKILL,
    GET_USER_PRIVATE_DATA,
    UPDATE_PASSWORD,
    DELETE_USER,
    GET_EMPLOYED_DETAILS
} from "./actionTypes";

const initialState = {
    user: [],
    aapas: [],
    // verify: [],
    // reset: [],
    signUp: [],
    personalInfo: {},
    publicationAndProjects: [],
    awards: [],
    currentAddress: {},
    homeAddress: {},
    careerPlan: {},
    userRoles: [],
    languageSkill: [],
    educationData: [],
    familDetails: {},
    experiance: [],
    freelance: [],
    contactDetails: [],
    lookingForJobes: [],
    userSkills: [],
    privateData: {},
    update_password: {},
    employed_details: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_EMAIL_PASSWORD:
            return { ...state, user: action.payload };
        case REGISTER_NEW_USER:
            return { ...state, user: action.payload }
        case FORGET_PASSWORD:
            return { ...state, forget: action.payload };
        case VERIFY_OTP:
            return { ...state, verify: action.payload };
        case RESET_PASSWORD:
            return { ...state, reset: action.payload };
        case LOGOUT_USER:
            return { ...state, user: action.payload };
        case UPDATE_USER_INFO:
            return { ...state, user: action.payload };
        case GET_USER_LOAN:
            return { ...state, aapas: action.payload };
        case GET_PERSONAL_INFO:
            return { ...state, personalInfo: action.payload };
        case GET_PUBLICATION_AND_PROJECTS:
            return { ...state, publicationAndProjects: action.payload };
        case GET_ACHIEVEMENTS_AND_AWARDS:
            return { ...state, awards: action.payload };
        case GET_USER_ADDRESS:
            return { ...state, currentAddress: { ...action.payload.currentAddress }, homeAddress: { ...action.payload.homeAddress } };
        case GET_USER_CAREER_PLAN:
            return { ...state, careerPlan: action.payload };
        case GET_EMPLOYED_DETAILS:
            return { ...state, employed_details: action.payload };
        case GET_USER_ROLES:
            return { ...state, userRoles: action.payload };
        case GET_USER_LANGUAGE_SKILL:
            return { ...state, languageSkill: action.payload };
        case GET_USER_EDUCATION_DATA:
            return { ...state, educationData: action.payload };
        case GET_USER_FAMILY_DETAILS:
            return { ...state, familDetails: action.payload };
        case GET_USER_EXPERIANCE:
            return { ...state, experiance: action.payload };
        case GET_USER_FREELANCE_SERVICES:
            return { ...state, freelance: action.payload };
        case GET_USER_CONTACT_DETAILS:
            return { ...state, contactDetails: action.payload };
        case GET_USER_LOOKING_FOR_JOBES:
            return { ...state, lookingForJobes: action.payload };
        case GET_USER_SKILL:
            return { ...state, userSkills: action.payload };
        case GET_USER_PRIVATE_DATA:
            return { ...state, privateData: action.payload };
        case UPDATE_PASSWORD:
            return { ...state, update_password: action.payload };
        case DELETE_USER:
            return { ...state, user: action.payload };
        default:
            return state;
    }
}