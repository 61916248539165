import React, { Component } from 'react'
import animationData from '../assets/lottie/loader.json';
import Lottie from 'react-lottie';
import BackButton from './BackButton';
import Ink from 'react-ink';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { withRouter } from 'react-router-dom';
export class Loading extends Component {
    onHandleBack = () => {


        this.props.history.goBack()
    }
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        return (
            <React.Fragment>
                <div className='backbutton-fixed'>
                    <div className='d-flex flex-row align-items-center position-relative' style={{ backgroundColor: '#fff' }}>
                        <div className='flex-shrink-1 mb-2 ms-3 me-3 position-absolute' style={{ left: '0px' }}>
                            {/* <div className='p-1 fw-bold' style={{ borderRadius: '100px', backgroundColor: '#035499' }} onClick={() => this.onHandleBack()}>
                                <Ink style={{ color: '#aaaaaa' }} />
                                <span className='fw-bold'>
                                    <HiOutlineChevronLeft size="2em" color='#000' />
                                </span>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="h-100 d-flex align-items-center bg-white justify-content-center overlay-loading ongoing-payment-spin" >
                    <Lottie
                        options={defaultOptions}
                        height={250}
                        width={250}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter((Loading));