// export const WEBSITE_URL = "http://127.0.0.1:8000/api";   //for localhost route uses
export const WEBSITE_URL = "https://hadia.in/hudawi-portal/public/api";   //for api route uses
export const WEBSITE_BASE_URL = "https://hadia.in/hudawi-portal/public/";  //for img purposes
// export const WEBSITE_BASE_URL = "http://127.0.0.1:8000/";   //for localhost route uses

//======================================== API ROUTES ========================================//

//====== Profile routes ======//
export const LOGIN_EMAIL_PASSWORD_URL = WEBSITE_URL + "/login-email-password";
export const GET_CHAPTER_URL = WEBSITE_URL + "/get-chapter-and-batch";
export const REGISTER_NEW_USER_URL = WEBSITE_URL + "/register-new-hudawi";
export const DELETE_USER_URL = WEBSITE_URL + "/delete-user"
// register-email-password

export const REGISTER_USER_DETAILS_URL = WEBSITE_URL + "/register-user-details";

export const FORGET_PASSWORD_URL = WEBSITE_URL + "/send-otp-to-mail";
export const VERIFY_OTP_URL = WEBSITE_URL + "/verify-otp-mail";
export const RESET_PASSWORD_URL = WEBSITE_URL + "/reset-password";
export const UPDATE_PASSWORD_URL = WEBSITE_URL + "/update-current-password";
export const GET_STATE_AND_DISTRICT_URL = WEBSITE_URL + "/get-state-and-district";
export const GET_UNIVERSITIES_URL = WEBSITE_URL + "/get-universities";
export const GET_INSTITUTE_URL = WEBSITE_URL + "/get-Institutes";
export const GET_COURSE_URL = WEBSITE_URL + "/get-course";
export const GET_JOBS_URL = WEBSITE_URL + "/get-job";
// export const GET_FREELANCE_URL = WEBSITE_URL + "/get-freelance-service";
export const GET_LANGUAGE_URL = WEBSITE_URL + "/get-language";


// export const GET_JOB_CATEGORY_URL = WEBSITE_URL + "/get-job-category";
export const UPDATE_USER_INFO_URL = WEBSITE_URL + "/update-user-info";
export const GET_ANNUAL_PACKAGE_URL = WEBSITE_URL + "/get-annual-package";


//====== home page routes ======//
export const GET_NOTI_EVENT_GALLERY_URL = WEBSITE_URL + "/get-notification-events-gallery";
export const GET_IMAGE_CATEGORY_URL = WEBSITE_URL + "/get-image-category";

//get user info 
export const GET_PERSONAL_INFO_URL = WEBSITE_URL + "/get-personal-info";

//update personal info
export const UPDATE_PERSONAL_INFO_URL = WEBSITE_URL + "/update-personal-info";

//Aapas
export const GET_USER_LOAN_URL = WEBSITE_URL + "/get-user-loan";
export const GET_ALL_LOANS_URL = WEBSITE_URL + "/get-all-loan";
export const GET_SINGLE_LOAN_URL = WEBSITE_URL + "/get-single-loan";
export const APPLY_FOR_LOAN_URL = WEBSITE_URL + "/apply-for-loan";
export const UPDATE_BANK_DETAILS_URL = WEBSITE_URL + "/update-bank-details";
export const GET_BANK_DETAILS_URL = WEBSITE_URL + "/get-bank-details";
export const PURCHASE_AAPAS_URL = WEBSITE_URL + "/purchase-aapas";
export const GET_ALL_FIXED_DEPOSIT_URL = WEBSITE_URL + "/get-fixed-deposit"
export const GET_ALL_RECURRING_DEPOSIT_URL = WEBSITE_URL + "/get-recurring-deposit";

// --------------Payments Starts----------
export const GET_ALL_HUDAWI_ANNUAL_FUND_URL = WEBSITE_URL + "/get-all-hudawi-annual-fund";
export const GET_ALL_PUBLIC_FUND_RAISING_URL = WEBSITE_URL + "/get-all-public-fund-Raising";

// donation payment
export const GET_DONATION_USER_URL = WEBSITE_URL + "/get-donation-payment";
export const PAYTM_DONATION_PAYMENT_URL = WEBSITE_URL + "/payment/donation-paytm";
export const DONATION_PAYMENT_SUCCESS_URL = WEBSITE_URL + "/payment/donation-status-paytm"

// Public fund Raising
export const GET_SINGLE_PUBLIC_FUND_RAISING_URL = WEBSITE_URL + "/get-single-public-fund";
export const GET_PUBLIC_FUND_RAISING_USER_URL = WEBSITE_URL + "/get-public-fund-payment";
export const PAYTM_PUBLIC_FUND_RAISING_PAYMENT_URL = WEBSITE_URL + "/payment/public-fund-paytm";
export const PUBLIC_FUND_RAISING_PAYMENT_SUCCESS_URL = WEBSITE_URL + "/payment/public-fund-status-paytm";

//Indirect Payment Request 
export const INDIRECT_PAYMENT_REQUEST_URL = WEBSITE_URL + "/indirect-payment-request";

// Zakath
export const GET_ZAKATH_USER_URL = WEBSITE_URL + "/get-zakath-payment";
export const PAYTM_ZAKATH_PAYMENT_URL = WEBSITE_URL + "/payment/zakath-paytm";
export const ZAKATH_PAYMENT_SUCCESS_URL = WEBSITE_URL + "/payment/zakath-status-paytm";

// haf payment
export const GET_HAF_USER_URL = WEBSITE_URL + "/get-haf-payment";
export const PAYTM_HAF_PAYMENT_URL = WEBSITE_URL + "/payment/haf-paytm";
export const HAF_PAYMENT_SUCCESS_URL = WEBSITE_URL + "/payment/haf-status-paytm";

// membership payment
export const GET_MEMBERSHIP_USER_URL = WEBSITE_URL + "/get-membership-payment";
export const PAYTM_MEMBERSHIP_PAYMENT_URL = WEBSITE_URL + "/payment/membership-paytm";
export const MEMBERSHIP_PAYMENT_SUCCESS_URL = WEBSITE_URL + "/payment/membership-status-paytm";

// --------------Payments End----------

// job-search

export const GET_SEARCH_USERS_AND_JOBS_URL = WEBSITE_URL + "/search-users-and-jobs"
export const GET_SEARCH_SINGLE_USER_URL = WEBSITE_URL + "/search-single-user"


//update project and publication

export const GET_PUBLICATION_AND_PROJECTS_URL = WEBSITE_URL + "/get-projects-and-publication"
export const UPDATE_PROJECTS_AND_PUBLICATION_URL = WEBSITE_URL + '/update-projects-and-publication'
//update achievements and wards
export const GET_ACHIEVEMENTS_AND_AWARDS_URL = WEBSITE_URL + '/get-achiements-and-awards'
export const UPDATE_ACHIEVEMENTS_AND_AWARDS_URL = WEBSITE_URL + '/update-achiements-and-awards'

//update address info
export const GET_USER_ADDRESS_URL = WEBSITE_URL + "/get-user-address"
export const UPDATE_USER_ADDRESS_URL = WEBSITE_URL + "/update-user-address"

//update career plan
export const GET_USER_CAREER_PLAN_URL = WEBSITE_URL + "/get-career-plan"
export const UPDATE_USER_CAREER_PLAN_URL = WEBSITE_URL + "/add-or-update-career-plan"
//common
export const GET_PROJECT_CATEGORIES_URL = WEBSITE_URL + "/get-project-categories"

//update user roles

export const GET_USER_ROLES_URL = WEBSITE_URL + "/get-user-roles"
export const ADD_OR_UPDATE_ROLES_URL = WEBSITE_URL + '/add-or-update-user-roles'


//update user language skill
export const GET_USER_LANGUAGE_SKILL_URL = WEBSITE_URL + "/get-user-language-skills"
export const UPDATE_USER_LANGUAGE_SKILL_URL = WEBSITE_URL + '/add-or-update-user-language'

//education data
export const GET_USER_EDUCATION_DATA_URL = WEBSITE_URL + '/get-user-education-data'
export const ADD_OR_UPDATE_HIGH_SCHOOL_URL = WEBSITE_URL + '/add-or-update-highSchool'
export const ADD_OR_UPDATE_GRADUATION_URL = WEBSITE_URL + '/add-or-update-graduation'
export const ADD_OR_UPDATE_MPHIL_OR_PHD_URL = WEBSITE_URL + '/add-or-update-mphil-phd'

//family details
export const GET_USER_FAMILY_DETAILS_URL = WEBSITE_URL + '/get-family-details'
export const UPDATE_USER_FAMILY_DETAILS_URL = WEBSITE_URL + '/update-user-family-details'
export const UPDATE_USER_CHILDRENS_DETAILS_URL = WEBSITE_URL + '/update-user-children-details'

//experiance
export const GET_USER_EXPERIANCE_URL = WEBSITE_URL + '/get-user-experiances'
export const UPDATE_USER_EXPERIANCE_URL = WEBSITE_URL + '/add-or-user-experiances'

//frelance
export const GET_FREELANCE_SERVICE_TYPE_URL = WEBSITE_URL + '/get-freelance-service'
export const GET_USER_FREELANCE_SERVICES_URL = WEBSITE_URL + '/get-user-freelance-service'
export const UPDATE_USER_FREELANCE_SERVICES_URL = WEBSITE_URL + '/update-user-freelance-service'
export const GET_USER_CONTACT_DETAILS_URL = WEBSITE_URL + '/get-contact-details'
export const UPDATE_USER_CONTACT_DETAILS_URL = WEBSITE_URL + '/add-or-update-contact-details'
export const GET_EMPLOYED_DETAILS_URL = WEBSITE_URL + '/get-employed-details'
export const ADD_OR_UPDATE_EMPLOYED_DETAILS_URL = WEBSITE_URL + '/add-or-update-employed-details'
export const GET_STUDYING_DETAILS_URL = WEBSITE_URL + '/get-studying-details'
export const UPDATE_STUDYING_DETAILS_URL = WEBSITE_URL + '/update-studying-details'
export const GET_USER_LOOKING_FOR_JOBES_URL = WEBSITE_URL + '/get-user-looking-for-jobes'
export const UPDATE_USER_LOOKING_FOR_JOBES_URL = WEBSITE_URL + '/update-user-looking-for-jobes'


//transactions
export const GET_USER_TRANSACTIONS_URL = WEBSITE_URL + '/get-user-transaction'

//available courses
export const GET_USER_ALL_COURSES_URL = WEBSITE_URL + '/get-all-courses'
export const GET_SINGLE_COURSE_URL = WEBSITE_URL + '/get-single-course'
export const JOIN_USER_FOR_COURSE_URL = WEBSITE_URL + '/join-for-course'

//get All Committees
export const GET_ALL_COMMITTEES_URL = WEBSITE_URL + "/get-all-committee"

//get all circulars 
export const GET_ALL_CIRCULARS_URL = WEBSITE_URL + '/get-all-circulars'

//downloads
export const GET_ALL_DOWNLOADS_URL = WEBSITE_URL + '/get-all-downloads'

//use full links
export const GET_ALL_USEFULL_LINKS_URL = WEBSITE_URL + '/get-all-use-full-links'
export const GET_ALL_PROJECTS_URL = WEBSITE_URL + '/get-all-projects'
export const GET_SINGLE_PROJECT_URL = WEBSITE_URL + '/get-single-projects'
export const GET_SINGLE_COMMITTE_URL = WEBSITE_URL + '/get-single-committee'
export const GET_CONTACT_MEMBERS_URL = WEBSITE_URL + '/get-contact-us'

//complaints
export const SEND_COMPLAINT_URL = WEBSITE_URL + '/send-complaint'

//welfare schemes 
export const GET_WELFARE_SCHEMES_URL = WEBSITE_URL + '/get-welfare-schemes'
export const SEND_WELFARE_REQUEST_URL = WEBSITE_URL + '/send-welfare-request'
export const GET_HADIA_EMPOWERMENT_CELLS_URL = WEBSITE_URL + '/get-empowerment-cell'

//date filtered transaction
export const GET_DATE_FILTERED_TRANSACTIONS_URL = WEBSITE_URL + "/get-my-filtered-transaction"

//job openings
export const GET_ALL_USER_JOB_OPENINGS_URL = WEBSITE_URL + '/get-user-job-openings';
export const GET_ALL_JOB_OPENINGS_URL = WEBSITE_URL + '/get-all-job-openings';
export const GET_SINGLE_JOB_VIEW_URL = WEBSITE_URL + '/get-single-job-view';
export const USER_JOB_APPLY_URL = WEBSITE_URL + '/user-job-apply';
export const GET_USER_FREELANCE_REQUEST_URL = WEBSITE_URL + '/user-freelance-request';
export const GET_USER__PUBLIC_FREELANCE_REQUEST_URL = WEBSITE_URL + '/user-pulbic-freelance-request';
export const GET_SINGLE_FREELANCE_REQUEST_URL = WEBSITE_URL + '/single-freelance-request';
export const GET_SINGLE_PUBLIC_FREELANCE_REQUEST_URL = WEBSITE_URL + '/single-public-freelance-request';

//about us

export const GET_ABOUT_US_URL = WEBSITE_URL + "/about-us";

//is freelance
export const UPDATE_USER_FREELANCE_STATUS_URL = WEBSITE_URL + '/change-freelance-status'

//help 
export const GET_HELP_URL = WEBSITE_URL + '/get-help'

//my request
export const GET_MY_REQUEST_URL = WEBSITE_URL + '/get-my-request'

//settings
export const GET_SETTINGS_URL = WEBSITE_URL + '/get-settings'

// get vakeel

export const GET_VAKEEL_USERS_URL = WEBSITE_URL + '/get-vakeel-users'

//get Skills

export const GET_SKILLS_URL = WEBSITE_URL + '/get-skills'

//get my skill
export const GET_USER_SKILL_URL = WEBSITE_URL + '/get-my-skills'

//update my skill
export const UPDATE_USER_SKILL_URL = WEBSITE_URL + "/update-my-skills"

//private data 
export const GET_USER_PRIVATE_DATA_URL = WEBSITE_URL + '/get-user-private'
export const UPDATE_USER_PRIVATE_DATA_URL = WEBSITE_URL + '/update-user-private'

//last visited date
export const USER_LAST_VISITED_DATE_URL = WEBSITE_URL + '/update-last-visited-date'

//update freelance reuest

export const UPDATE_FREELANCE_REQUEST_URL = WEBSITE_URL + '/freelance-request'

//panchayath

export const GET_PANCHAYATH_DETAILS_URL = WEBSITE_URL + '/get-panchayath'

//get greviance Members
export const GET_GREVIANCE_MEMBERS_URL = WEBSITE_URL + '/get-greviance-members'