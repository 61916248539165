import {
  GET_ALL_LOANS,
  GET_SINGLE_LOAN,
  GET_ALL_FIXED_DEPOSIT,
  GET_ALL_RECURRING_DEPOSIT,
  GET_BANK_DETAILS,
} from "./actionTypes";

const initialState = {
  aapas: [],
  singleLoan: [],
  fixed: [],
  recurring: [],
  bank_details: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LOANS:
      return { ...state, aapas: action.payload };
    case GET_SINGLE_LOAN:
      return { ...state, singleLoan: action.payload };
    case GET_ALL_FIXED_DEPOSIT:
      return { ...state, fixed: action.payload };
    case GET_ALL_RECURRING_DEPOSIT:
      return { ...state, recurring: action.payload };
    case GET_BANK_DETAILS:
      return { ...state, bank_details: action.payload };
    default:
      return state;
  }
}
