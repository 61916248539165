import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import Ink from 'react-ink';
import { withRouter } from 'react-router-dom';

class BackButtonHeader extends Component {

    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        

        return (
            <React.Fragment>
                <div className='header-fixed'>
                    <div className='center-cropped py-4' style={{ backgroundColor: '#035499', borderRadius: '0px 0px 30px 30px' }}>
                        <div className='d-flex flex-row align-items-center position-relative' >
                            <div className='flex-shrink-1 mb-2 ms-3 me-3 position-absolute' style={{ left: '0px' }}>
                                <div className='fw-bold' onClick={() => this.onHandleBack()}
                                    style={{ borderRadius: '18px', border: 'none', outline: 'none', background: '#035499', paddingRight: '12px', paddingLeft: '11px', paddingTop: '8px', paddingBottom: '9px' }}>
                                    <Ink style={{ color: '#aaaaaa' }} />
                                    <span className='text-white fw-bold'> <HiOutlineChevronLeft size="1.5em" /></span>
                                </div>
                            </div>
                            <div className='flex-grow-1 text-white text-center fs-5'>
                                <span className='mt-3' style={{ fontWeight: 'bold' }}>{this.props.title}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({

})


export default withRouter(connect(mapStateToProps, {

})(BackButtonHeader))