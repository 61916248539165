/* eslint-disable import/no-anonymous-default-export */
import {
    GET_NOTI_EVENT_GALLERY,
    GET_IMAGE_CATEGORY
} from "./actionTypes";

const initialState = {
    home: [],
    image_category: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_NOTI_EVENT_GALLERY:
            return { ...state, home: action.payload };
        case GET_IMAGE_CATEGORY:
            return { ...state, image_category: action.payload };
        default:
            return state;
    }
}