/* eslint-disable import/no-anonymous-default-export */

import { GET_SEARCH_SINGLE_USER, GET_SEARCH_USERS_AND_JOBS } from './actionTypes';


const initialState = {
    // hudawis: [],
    // jobopenings: [],
    search: [],
    search_single_user: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SEARCH_USERS_AND_JOBS:
            return { ...state, search: action.payload };
        case GET_SEARCH_SINGLE_USER:
            return { ...state, search_single_user: action.payload };
        default:
            return state;
    }
}