/* eslint-disable import/no-anonymous-default-export */
import {
    ADD_CAREER_STATUS,
    ADD_USER_ADDRESS,
    ADD_USER_HIGHER_SECONDARY_DETAILS,
    ADD_USER_UG_DETAILS,
    ADD_EXPERIANCE,
    ADD_USER_MPHIL_DETAILS,
    ADD_USER_OTHER_DETAILS,
    ADD_USER_PG_DETAILS,
    ADD_USER_PHD_DETAILS,
    ADD_USER_DIPLOMA_DETAILS,
    ADD_USER_EMPLOYED_DETAILS,
    ADD_USER_STUDYING_DETAILS,
    ADD_USER_LOOKING_FOR_JOB,
    ADD_USER_CAREER_PLAN_DETAILS,
    ADD_USER_FREELANCE_DETAILS,
    ADD_LANGUAGE_DETAILS,
    ADD_FAMILY_DETAILS,
    ADD_PROJECT_DETAILS,
    ADD_AWARD_DETAILS,
    ADD_CHILDREN_DETAILS,
    REGISTER_USER_DETAILS,
    COMPLETE_TAB,
    ADD_ROLES_AND_RESPONSIBILITY
} from "./actionTypes";

const initialState = {
    register: [],
    currentAddress: {},
    homeAddress: {},
    sslcDetails: {},
    plusTwoDetails: {},
    studyingDetails: {},
    userExperiance: [],
    ugDetails: [],
    pgDetails: [],
    phdDetails: [],
    awards: [],
    diplomaDetails: [],
    employedDetails: [],
    looking_for_jobDetails: [],
    career_planDetails: [],
    freelance_serviceDetails: [],
    language: {},
    familyDetails: [],
    childrenDetails: [],
    projectDetails: [],
    awardDetails: [],
    roles_and_responsibility: [],
    completeTab: 0,
    otherDetails: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_USER_ADDRESS:
            return { ...state, currentAddress: action.payload.currentAddress, homeAddress: action.payload.homeAddress };
        case ADD_USER_HIGHER_SECONDARY_DETAILS:
            return { ...state, sslcDetails: action.payload.sslcDetails, plusTwoDetails: action.payload.plusTwoDetails };
        case ADD_USER_UG_DETAILS:
            return { ...state, ugDetails: action.payload };
        case ADD_USER_MPHIL_DETAILS:
            return { ...state, mphilDetails: action.payload };
        case ADD_USER_OTHER_DETAILS:
            return { ...state, otherDetails: action.payload };
        case ADD_USER_PG_DETAILS:
            return { ...state, pgDetails: action.payload };
        case ADD_USER_PHD_DETAILS:
            return { ...state, phdDetails: action.payload };
        case ADD_USER_DIPLOMA_DETAILS:
            return { ...state, diplomaDetails: action.payload };
        case ADD_CAREER_STATUS:
            return { ...state, careerStatus: action.payload }
        case ADD_EXPERIANCE:
            return { ...state, userExperiance: action.payload };
        case ADD_USER_EMPLOYED_DETAILS:
            return { ...state, employedDetails: action.payload };
        case ADD_USER_STUDYING_DETAILS:
            return { ...state, studyingDetails: action.payload };
        case ADD_USER_LOOKING_FOR_JOB:
            return { ...state, looking_for_jobDetails: action.payload }
        case ADD_USER_CAREER_PLAN_DETAILS:
            return { ...state, career_planDetails: action.payload }
        case ADD_FAMILY_DETAILS:
            return { ...state, familyDetails: action.payload }
        case ADD_USER_FREELANCE_DETAILS:
            return { ...state, freelance_serviceDetails: action.payload }
        case ADD_ROLES_AND_RESPONSIBILITY:
            return { ...state, roles_and_responsibility: action.payload }
        case ADD_LANGUAGE_DETAILS:
            let data = { ...action.payload }
            return { ...state, language: data };
        case ADD_PROJECT_DETAILS:
            // console.log(action.payload)
            return { ...state, projectDetails: action.payload }
        case ADD_AWARD_DETAILS:
            // console.log(action.payload)
            return { ...state, awardDetails: action.payload }
        case ADD_CHILDREN_DETAILS:
            // console.log(action.payload)
            return { ...state, childrenDetails: action.payload }
        case REGISTER_USER_DETAILS:
            // console.log(action.payload)
            return { ...state, register: action.payload }

        case COMPLETE_TAB:
            console.log(action.payload)
            return { ...state, completeTab: action.payload }

        default:
            return state;
    }
}
