import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

// eslint-disable-next-line import/no-anonymous-default-export
export default (initialState) => {
	initialState = JSON.parse(window.localStorage.getItem("state")) || initialState;
	const middleware = [thunk];

	const store = createStore(
		rootReducer,
		initialState,
		compose(
			applyMiddleware(...middleware)
			// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
		)
	);

	store.subscribe(() => {
		const state = store.getState();
		//save the config to localstorage
		// for (const setting in state.settings.settings) {
		// 	if (state.settings.settings.hasOwnProperty(setting)) {
		// 		const element = state.settings.settings[setting];
		// 		localStorage.setItem(element.key, element.value);
		// 	}
		// }

		// for (const translation in state.languages.language) {
		// 	if (state.languages.language.hasOwnProperty(translation)) {
		// 		const trans = state.languages.language[translation];
		// 		localStorage.setItem(translation, trans);
		// 	}
		// }

		const persist = {
			user: state.user,
			register: state.register,
			common: state.common,
			payment: state.payment,
			home: state.home,
			aapas: state.aapas,
			jobs: state.jobs,
			search: state.search,

		};
		localStorage.setItem("state", JSON.stringify(persist));

		if (window.ReactNativeWebView) {
			const response = {
				type: 'state',
				data: persist
			};
			window.ReactNativeWebView.postMessage(JSON.stringify(response));
		}
		// const user = {
		//     user: state.user
		// };
		// window.localStorage.setItem("user", JSON.stringify(user.user));
	});

	return store;
};
