export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS";
export const ADD_USER_HIGHER_SECONDARY_DETAILS = "ADD_USER_HIGHER_SECONDARY_DETAILS";
export const ADD_USER_UG_DETAILS = "ADD_USER_UG_DETAILS";
export const ADD_USER_MPHIL_DETAILS = "ADD_USER_MPHIL_DETAILS";
export const ADD_USER_OTHER_DETAILS = "ADD_USER_OTHER_DETAILS";

export const ADD_USER_PG_DETAILS = "ADD_USER_PG_DETAILS";
export const ADD_USER_PHD_DETAILS = "ADD_USER_PHD_DETAILS";
export const ADD_USER_DIPLOMA_DETAILS = "ADD_USER_DIPLOMA_DETAILS";
export const ADD_CAREER_STATUS = "ADD_CAREER_STATUS"
export const ADD_EXPERIANCE = "ADD_EXPERIANCE"
export const ADD_USER_FREELANCE_DETAILS = "ADD_USER_FREELANCE_DETAILS";


export const ADD_USER_EMPLOYED_DETAILS = "ADD_USER_EMPLOYED_DETAILS"
export const ADD_USER_STUDYING_DETAILS = "ADD_USER_STUDYING_DETAILS"
export const ADD_USER_LOOKING_FOR_JOB = "ADD_USER_LOOKING_FOR_JOB"
export const ADD_USER_CAREER_PLAN_DETAILS = "ADD_USER_CAREER_PLAN_DETAILS"
export const ADD_LANGUAGE_DETAILS = "ADD_LANGUAGE_DETAILS"
export const ADD_FAMILY_DETAILS = "ADD_FAMILY_DETAILS"
export const ADD_PROJECT_DETAILS = "ADD_PROJECT_DETAILS"
export const ADD_AWARD_DETAILS = "ADD_AWARD_DETAILS"
export const ADD_ROLES_AND_RESPONSIBILITY = "ADD_ROLES_AND_RESPONSIBILITY"

export const COMPLETE_TAB = "COMPLETE_TAB"


export const ADD_AWARDS_DETAILS = "ADD_AWARDS_DETAILS"

export const ADD_CHILDREN_DETAILS = "ADD_CHILDREN_DETAILS"
export const REGISTER_USER_DETAILS = "REGISTER_USER_DETAILS"
