export const GET_COURSE ='GET_COURSE'
export const GET_JOBS = 'GET_JOBS'
export const GET_FREELANCE_SERVICE = 'GET_FREELANCE_SERVICE'
export const GET_PROJECT_CATEGORIES="GET_PROJECT_CATEGORIES"

export const GET_STATE_AND_DISTRICT = 'GET_STATE_AND_DISTRICT'
export const GET_ANNUAL_PACKAGE = 'GET_ANNUAL_PACKAGE'

export const GET_LANGUAGE = 'GET_LANGUAGE'
export const GET_FREELANCE_SERVICE_TYPE = 'GET_FREELANCE_SERVICE_TYPE'
export const GET_SKILLS = 'GET_SKILLS'
export const ALL_DEPARTMENTS = 'ALL_DEPARTMENTS'
export const ALL_COMMITTEE = 'ALL_COMMITTEE'
export const GET_CELLS = 'GET_CELLS'
export const GET_UNIVERSITIES = 'GET_UNIVERSITIES'
export const GET_INSTITUTE = 'GET_INSTITUTE'
export const GET_PANCHAYATH_DETAILS = 'GET_PANCHAYATH_DETAILS'




